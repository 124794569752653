import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Box, styled, Typography } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import { useDashboard } from "../../../models/company/useDashboard"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import enTranslations from "../../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeTitle/ja"
import { EmployeeHomeContent } from "./EmployeeHomeContent"
import { EmployeeHomeLayoutContentComponent } from "./EmployeeHomeLayoutContentComponent"

////////// Title

const EmployeeHomeLayoutTitleStyle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  margin: "0 10px 0 0",
})

const EmployeeHomeLayoutTitle: React.FC = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <EmployeeHomeLayoutTitleStyle>
      {translations.seatStatus}
    </EmployeeHomeLayoutTitleStyle>
  )
}

////////// Content

const EmployeeHomeLayoutContent: React.FC = () => {
  const {
    layoutImg,
    layout,
    seats,
    meetingRooms,
    areas,
    setFloorId,
    setBranchId,
    setCompanyId,
  } = useDashboard()
  const { floorId, branchId, companyRelations } = useCompanyRelations()

  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  useEffect(() => {
    if (companyRelations.id > 0) {
      setFloorId(floorId)
      setBranchId(branchId)
      setCompanyId(companyRelations.id)
    }
  }, [companyRelations])

  return (
    <Box>
      {layout && layoutImg && layoutImg.length > 0 ? (
        <EmployeeHomeLayoutContentComponent
          seats={seats}
          meetingRooms={meetingRooms}
          areas={areas}
          layoutImg={layoutImg}
          layout={layout}
          companyRelations={companyRelations}
        />
      ) : (
        <Typography align="center" fontSize="12px">
          {translations.LayoutNot}
        </Typography>
      )}
    </Box>
  )
}

////////// Icon

const EmployeeLeftIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
})

const EmployeeLeftIcon = (
  <EmployeeLeftIconStyled>
    <img
      src={`${process.env.PUBLIC_URL}/images/icons/seat-green.svg`}
      style={{
        height: "25px",
        width: "25px",
      }}
    />
  </EmployeeLeftIconStyled>
)

const EmployeeRightIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
})

const EmployeeRightIcon = () => {
  const navigate = useNavigate()

  const EmployeeRightIconOnClick = () => {
    navigate("/employee/layouts")
  }

  return (
    <EmployeeRightIconStyled>
      <img
        src={`${process.env.PUBLIC_URL}/images/icons/next-gray.svg`}
        style={{
          height: "15px",
          width: "15px",
          cursor: "pointer",
        }}
        onClick={EmployeeRightIconOnClick}
      />
    </EmployeeRightIconStyled>
  )
}

export const EmployeeHomeLayout = () => {
  return (
    <EmployeeHomeContent
      title={<EmployeeHomeLayoutTitle />}
      content={<EmployeeHomeLayoutContent />}
      leftIcon={EmployeeLeftIcon}
      rightIcon={EmployeeRightIcon()}
      areaName="Layout"
    />
  )
}
